import {
  Flex,
  Text,
  Button,
  Box,
  Heading,
  Image,
  Card,
  CardBody,
  CardHeader,
  SimpleGrid,
  Stack,
  Container,
  Divider,
  Spinner,
  Link,
  Select,
} from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { Section } from "../section";
import SpinnerFull from "../spinner-full";
import { siteConfig } from "../../configuration/config";
import { Features } from "../features";
import FeaturePresentation from "../feature-presentation";
import LandingFaq from "../landing-faq";
import PricingSwitch from "../pricing-switch";
import { Product } from "../../pages/api/plans";
import { LandingLocale } from "../../localization/landing";
import AvatarList from "./avatar-list";
import TestimonialList from "./testimonials";
import { IoOpenOutline } from "react-icons/io5";
import { useLandingUtils } from "../../hooks/use-landing-utils";

export interface LandingPageProps {
  baseLangCode: string;
  l: LandingLocale;
}

export default function LandingPageKo({ l }: LandingPageProps) {
  const { onSignupClick, setBillingSelection, promotionCodeId, products } = useLandingUtils();

  if (siteConfig.overrideLanding) {
    return <SpinnerFull />;
  }

  return (
    <Box>
      <Box backgroundColor="brand.blue.300" py="12">
        <Section>
          <Flex flexDir="column" flex="1">
            <Heading
              as="h1"
              color={"brand.gray"}
              fontSize={"5xl"}
              lineHeight="1"
              fontWeight="bold"
              mt={{ base: "30px", lg: "70px" }}
              mb={4}
            >
              {`Don't Speak, Let's Talk!`}
            </Heading>
            <Heading as="h2" lineHeight="1.2" color={"brand.gray"} fontSize={"2xl"}>
              {`나만의 외국어 프리토킹 파트너 Talkio AI`}
            </Heading>
            <Text mt={5}>단순 스피킹과는 달리, 많은 대화량이 필요한 프리토킹</Text>
            <Text mt={3} mb={5}>
              토키오가 최신 AI기술을 기반으로 외국어 대화량을 획기적으로 늘려 드립니다
            </Text>

            <Button
              colorScheme={"brand.blue"}
              bg="brand.blue.100"
              color={"black"}
              maxW={{ base: "100%", sm: "300px" }}
              onClick={onSignupClick}
            >
              {l["landing.button.freeTrial"]}
            </Button>
            <Text maxW={{ base: "100%", sm: "300px" }} textAlign="center" fontSize="xs" mt={1}>
              {l["landing.creditCardNotice"]}
            </Text>
          </Flex>
          <Flex
            display={{ base: "none", lg: "flex" }}
            flex="1"
            justifyContent={{ base: "center" }}
            alignItems="center"
          >
            <Image
              src="/images/landing/alt-header.png"
              alt={l["landing.imageAlt.landing"]}
              alignSelf={"center"}
              width={"300px"}
            />
          </Flex>
        </Section>
      </Box>

      {/* <Box
        backgroundColor="brand.gray.100"
        color="brand.gray.600"
        textAlign={"center"}
        p={10}
        fontSize={"sm"}
      >
        <Flex justifyContent={"center"} alignItems="center">
          <Image
            src="/vector/laurel-left.svg"
            alt={l["landing.imageAlt.laurelLeft"]}
            w="50px"
            mr={3}
            opacity={0.3}
          />
          <Box>
            <Text fontSize={{ base: "2xs", sm: "xs", md: "md" }}>
              {l["landing.text.voiceControlTeam"]}
            </Text>

            <Box fontSize={"lg"}>
              <Text fontWeight={"bold"}>{l["landing.text.trustedBy"]}</Text>
            </Box>
          </Box>
          <Image
            src="/vector/laurel-right.svg"
            alt={l["landing.imageAlt.laurelRight"]}
            w="50px"
            ml={3}
            opacity={0.3}
          />
        </Flex>
      </Box> */}

      <Box backgroundColor="brand.blue.100" py="12">
        <Section>
          <Flex w="full" flexDir={"column"}>
            <Flex flexDir={"column"} textAlign="center">
              <Text fontStyle="italic">영어 문서도 줄줄 읽고,</Text>
              <Text mt={2}>원고만 있으면 영어 스피치도 하겠는데,</Text>
              <Text mt={2} fontWeight="bold" fontSize="lg" color="brand.blue.800">
                외국인과 단 10분의 대화가 진땀 나는 건 왜일까?
              </Text>
            </Flex>

            <Flex justifyContent="center" flexDir={{ base: "column", lg: "row" }}>
              <Flex flexDir={"column"} textAlign={{ base: "center", lg: "right" }} my={4} flex="1">
                <Heading fontWeight={"900"} color="brand.blue.900">
                  Speaking
                </Heading>
                <Box fontSize="sm" lineHeight={1.6} my={3}>
                  <Text>공식적이고 일방적인 상황:</Text>
                  <Text>
                    {`"Speak"는 대체로 공식적이고, 일방적인 상황에서 사용됩니다. 발표나 연설, 전화 통화에서 한 사람이 주도적으로 말할 때 "speak"를 사용합니다.`}
                  </Text>
                  <Text>{`예: "She will speak at the conference."`}</Text>
                </Box>
              </Flex>
              <Flex>
                <Heading
                  mt={12}
                  fontWeight={"900"}
                  fontSize="xl"
                  textAlign="center"
                  mx="4"
                  lineHeight={1}
                  display={{ base: "none", lg: "block" }}
                  color="brand.blue.900"
                >
                  vs
                </Heading>
              </Flex>
              <Flex flex="1" flexDir={"column"} textAlign={{ base: "center", lg: "left" }} my={4}>
                <Heading fontWeight={"900"} color="brand.blue.900">
                  Talking
                </Heading>
                <Box fontSize="sm" lineHeight={1.6} my={3}>
                  <Text>일상적이고 대화 중심의 상황:</Text>
                  <Text>{`"Talk"는 덜 공식적이며, 일상적인 대화를 표현할 때 사용됩니다.`}</Text>
                  <Text>
                    {`두 사람 이상이 서로 주고받으며 대화할 때 "talk"를 주로
                    씁니다.`}
                  </Text>
                  <Text>{`예: "Let's talk about your plans."`}</Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Section>
      </Box>

      <Box backgroundColor="brand.green.100" py="12">
        <Section>
          <Flex w="full" flexDir={"column"}>
            <Flex flexDir={{ base: "column", lg: "row" }} textAlign="center">
              <Flex flex="1" justifyContent={"center"} alignItems="center">
                <Heading
                  p={6}
                  fontSize="5xl"
                  lineHeight={0.8}
                  fontWeight={"900"}
                  color="brand.blue.900"
                  transform="rotate(-5deg)"
                  mb={{ base: 12, lg: 0 }}
                >
                  우린, 대화가 필요해!
                </Heading>
              </Flex>
              <Box p={8} bg="rgba(255,255,255,1)" borderRadius={5} flex="1">
                <Text
                  fontWeight="bold"
                  fontSize="xl"
                  color="brand.green.900"
                  maxW="500px"
                  mx="auto"
                  mt={5}
                >
                  {`외국어 학습의 핵심은 상대방과 주고받는 ‘대화’의 양에 있습니다.`}
                </Text>
                <Text mt={2} fontStyle="italic" fontSize="xs">
                  {`상대방의 말을 이해하는 것만으로는 새로운 언어 습득이 불가능합니다. 언어 습득은 일방적인 의사소통이 아닌, 상호작용을 통해 이루어집니다. 
                  학습자는 상호작용 중에 자신의 언어적 오류를 인식하고, 피드백을 받으며 필요한 수정 과정을 거쳐 언어 능력을 향상시킵니다.`}
                </Text>
                <Text mt={2} fontWeight="bold" fontSize="sm" mb={4}>
                  Output Hypothesis, Merrill Swain
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Section>
      </Box>

      <Section>
        <Flex flexDir="column">
          <Flex flexDir="column" textAlign={"center"}>
            <Text mt={7} fontStyle="italic">
              {`„어학연수를 떠날 여건도 안되고, 영어마을로 이사를 갈 수도 없는데...“`}
            </Text>
            <Heading mt={8} mb={3} fontSize="5xl" fontWeight={100} lineHeight={1}>
              <strong>나만의</strong> 외국어 프리토킹 파트너 토키오를 만나보세요
            </Heading>
            <Text mb={12}>...그리고 외국어 연습량이 놀랍게 증가하는 것을 경험해 보세요</Text>
          </Flex>
          <SimpleGrid
            spacing={4}
            templateColumns={{
              base: "repeat(auto-fill, minmax(100%, 1fr))",
              lg: "repeat(auto-fill, minmax(30%, 1fr))",
            }}
            width={"100%"}
          >
            <Card borderTop={"5px solid"} borderColor="brand.primary.200">
              <CardHeader>
                <Heading fontSize="xl" lineHeight="1">
                  부담 없는 AI 선생님
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  {`토키오는 OpenAI의 ChatGPT를 지원하는 음성 제어 전문팀의 최첨단 AI 기술로 개발되었습니다.
                   당신을 완벽히 이해하는 직관적인 AI 튜터와 함께, 대화 연습이 자연스럽게 이루어질 수 있는 환경을 제공합니다.`}
                </Text>
              </CardBody>
            </Card>
            <Card borderTop={"5px solid"} borderColor="brand.secondary.600">
              <CardHeader>
                <Heading fontSize="xl" lineHeight="1">
                  13개 언어 지원
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  영어뿐만 아니라 일본어, 중국어, 스페인어, 프랑스어, 독일어 등 다양한 언어 옵션을
                  제공합니다.
                </Text>
                <Box mt="5">
                  <Select>
                    <option>영어</option>
                    <option>포르투갈어</option>
                    <option>스페인어</option>
                    <option>독일어</option>
                    <option>이탈리아어</option>
                    <option>프랑스어</option>
                    <option>중국어 (만다린)</option>
                    <option>일본어</option>
                    <option>네덜란드어</option>
                    <option>폴란드어</option>
                    <option>히브리어</option>
                    <option>덴마크어</option>
                    <option>한국어</option>
                  </Select>
                </Box>
              </CardBody>
            </Card>
            <Card borderTop={"5px solid"} borderColor="brand.green.400">
              <CardHeader>
                <Heading fontSize="xl" lineHeight="1">
                  최첨단 음성 인식 기술
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  {`토키오는 프리미엄 음성을 기반으로 작동합니다. 인기 있는 언어의 다양한 방언을 지원합니다.
                   토키오의 고급 언어 기술과 함께 흥미로운 대화를 이어가 보세요.`}
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>
        </Flex>
      </Section>

      <Box>
        {/* <Heading
          as="h3"
          textAlign={"center"}
          mt={10}
          mb={6}
          mx={2}
          fontWeight="thin"
          fontSize={"5xl"}
          lineHeight={"1"}
        >
          {l["landing.sectionTitle.meetTutors"]}
        </Heading>
        <Text textAlign={"center"} mb={12}>
          {l["landing.sectionText.moreThanTutors"]}
        </Text> */}
        <Box
          w="100%"
          overflowX={{ base: "auto", md: "hidden" }}
          bgGradient={"linear-gradient(to right, brand.blue.600, brand.blue.800)"}
        >
          <Text
            textAlign={"center"}
            mt={8}
            fontSize="xs"
            fontWeight={"bold"}
            fontStyle="italic"
            color="white"
          >
            {l["landing.sectionText.moreThanTutors"]}
          </Text>
          <Flex justifyContent={{ base: "flex-start", md: "space-around" }} pt={8} pb={8}>
            <AvatarList l={l} />
          </Flex>
        </Box>
      </Box>

      <Box id="feature-v1">
        <Features l={l} />
        <Divider />
      </Box>

      <Box id="feature-v2" display={"none"} backgroundColor="white">
        <Box pt={5} mt={5}>
          <Heading
            as="h3"
            textAlign={"center"}
            mt={10}
            mb={6}
            mx={2}
            fontWeight="thin"
            fontSize={"5xl"}
            lineHeight={"1"}
          >
            {l["landing.sectionTitle.featureSpotlight"]}
          </Heading>
          <Text textAlign={"center"} mb={12} px="5">
            {l["landing.sectionText.featureSpotlight"]}
          </Text>
        </Box>

        <Divider maxW="1000px" m="0 auto" />

        <FeaturePresentation
          l={l}
          onButtonClick={() => {
            const product = products?.[0];

            if (product) {
              setBillingSelection(product.id);
            }

            onSignupClick();
          }}
        />
      </Box>
      {/* 
      <Section>
        <Flex flexDir={"column"} flex="1">
          <Heading
            color={"brand.gray"}
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            lineHeight={1}
            mt={{ base: "50px", lg: "100px" }}
          >
            {l["landing.text.safeEnvironment"]}
          </Heading>
          <Text mt="4" mb={{ base: 12, md: 0 }}>
            {l["landing.text.supportiveEnvironment"]}
          </Text>
        </Flex>
        <Flex flex="1" display={{ base: "none", md: "block" }}>
          <Image
            src="/landing-2.jpeg"
            alt={l["landing.imageAlt.speakingWithAI"]}
            height={"auto"}
            alignSelf={"center"}
            width={{ base: "250px", sm: "350px", md: "450px", lg: "100%" }}
          />
        </Flex>
      </Section> */}

      <Box backgroundColor="gray.100">
        <Section>
          <Flex w="100%" flexDir={"column"}>
            <Heading
              as="h3"
              textAlign={"center"}
              mt={10}
              mb={6}
              mx={2}
              fontWeight="thin"
              fontSize={"5xl"}
              lineHeight={"1"}
            >
              {l["landing.sectionTitle.insights"]}
            </Heading>
            <Text textAlign={"center"} mb={12}>
              {l["landing.sectionText.realStories"]}
            </Text>

            <Box maxW="600px" m="0 auto" mb="3" id="legacy-testimonials">
              <TestimonialList l={l} />
            </Box>
          </Flex>
        </Section>
      </Box>

      <Box py={{ base: "50px", md: "50px" }} id="faq">
        <Section>
          <Flex w="100%" flexDir={{ base: "column", md: "row" }}>
            <Box flex="1">
              <Heading size="sm" fontWeight={"bold"}>
                {l["landing.sectionTitle.faq"]}
              </Heading>
              <Text mt={2} mb={7}>
                {l["landing.sectionText.faq"]}
              </Text>
            </Box>
            <Box flex="1">
              <LandingFaq l={l} />
            </Box>
          </Flex>
        </Section>
      </Box>
      <Box backgroundColor="gray.100">
        <Box as="section">
          <Container py={{ base: "16", md: "24" }}>
            <Stack spacing={{ base: "4", md: "6" }} alignItems="center">
              <Stack spacing="3" textAlign={"center"}>
                <Heading size={{ base: "md", md: "lg" }}>
                  {l["landing.sectionTitle.pricing"]}
                </Heading>
              </Stack>
              <Text
                fontSize={{ base: "md" }}
                color="fg.muted"
                maxW="2xl"
                textAlign={"center"}
                id="pricing"
                mb={8}
              >
                {l["landing.sectionText.unlockPotential"]}
              </Text>
            </Stack>

            {products ? (
              <PricingSwitch
                l={l}
                promotionCodeId={promotionCodeId}
                products={products}
                buttonCallback={({ productId }) => {
                  setBillingSelection(productId);
                  onSignupClick();
                }}
              />
            ) : (
              <Flex minH="300px" alignItems="center" justifyContent="center">
                <Spinner size={"xl"} />
              </Flex>
            )}

            <Flex
              textAlign={"center"}
              mt={3}
              w="full"
              alignItems={"center"}
              color="brand.gray.500"
              justifyContent={"center"}
            >
              <Text fontSize="md">{l["landing.sectionText.offersForPartners"]}</Text>
              <Link ml="2" as="a" href="/partners/schools">
                <IoOpenOutline />
              </Link>
            </Flex>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
